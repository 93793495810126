
<script lang="ts" setup>

import logo from '~/assets/img/md-logo.jpg'
//import wordLogo from '~/assets/img/bf-logo.png'

import { useColors } from 'vuestic-ui';
const { getComputedColor, setColors } = useColors();
const primaryColor = getComputedColor('primary');

const route = useRoute();

const menuData = useMenuStore();


await callOnce('config', async () => {
    //let bid = branchesData.Branches[branchIdx]['branchId']
    console.log('HERE 1 Layout')
    await menuData.fetchConfig(`${route.params.id}-prod`)
    if (menuData.config && menuData.config["COLOR_PRIMARY"]) {
        primaryColor.value = menuData.config["COLOR_PRIMARY"].value
        setColors({ primary: `${menuData.config["COLOR_PRIMARY"].value}`})
    }

    return 1
});

if (menuData.config && menuData.config["COLOR_PRIMARY"]) {
    primaryColor.value = menuData.config["COLOR_PRIMARY"].value
    setColors({ primary: `${menuData.config["COLOR_PRIMARY"].value}`})
}

// const { pending, data: config, error, refresh } = await useFetch('/api/w/storeConfig', {
//    headers: {
//     'X-Brownie-Branch-ID': `${route.params.id}-prod`
//    },
// })

// watch(config, (data) => {
//     if (!error && data && data["COLOR_PRIMARY"]) {
//         console.log(data)
//         primaryColor.value = data["COLOR_PRIMARY"].value
//     }
// })

// refresh()

</script>

<template>
  <div>
    <VaNavbar
    color="primary"
    class="h-26"
    bordered shadowed
  >
    <template #left>
      <VaNavbarItem class="logo">
        <!-- <VaAvatar 
          size="large"
          font-size="30px"
          class="mr-6"
          
        >
        </VaAvatar> -->
        
      <!-- ?<VaImage :src="logo" fit="contain" stlye="width: 40px; height: 40px;" lazy/> -->
      <NuxtLink to="/" aria-label="Go to menu home page">
        <div class="flex flex-row">
        <VaImage :src="logo" fit="contain" class="mr-2 w-[50px] sm:w-[70px]" lazy/>
        </div>
      </NuxtLink>

      <NuxtLink :to="`/${route.params.id}`"> <span class="font-semibold">Menu</span> </NuxtLink>

    </VaNavbarItem>

      <VaNavbarItem class="hidden sm:block">
      <NuxtLink :to="`/${route.params.id}/links`" > <span class="font-semibold"> Links </span> </NuxtLink>
      </VaNavbarItem>
    </template>
    <template #right>
      <!-- <VaNavbarItem class="hidden sm:block">
        <NuxtLink to="/loyalty" v-on:click.native="goToLoyalty"> <span class="font-semibold">Loyalty</span> </NuxtLink>
      </VaNavbarItem> -->
      <!-- <VaNavbarItem class="hidden sm:block">
        <NuxtLink to="/links" > <span class="font-semibold"> Links </span> </NuxtLink>
      </VaNavbarItem> -->
      
      <!-- <VaNavbarItem class="hidden sm:block">
        <ClientOnly>
          <NotificationDropdown class="app-navbar-actions__item" @login="showAuthModal = !showAuthModal" />
        </ClientOnly>
      </VaNavbarItem> -->
      
    </template>
  </VaNavbar>
    <slot />
    <div class="flex flex-col place-content-center place-items-center w-full" style="background-color: var(--va-background-primary);">
      <p class="text-sm mb-1 text-center">
        Powered by <span style="color:#9b2c2c">FoodIn.Bio</span>
      </p>
    </div>
  </div>
</template>

<style>
.page-enter-active,
.page-leave-active {
  transition: all 0.4s;
}
.page-enter-from,
.page-leave-to {
  opacity: 0;
  filter: blur(1rem);
}
</style>
